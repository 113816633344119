.search-form__holder {
    .--loading {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5rem;

        .--spinner {
            width: 2rem;
            height: 1.8rem;
        }
    }

    .--remove {
        cursor: pointer;
        position: absolute;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='8px' height='8px' viewBox='0 0 8 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Path' fill='%23333333' points='0.888616046 0 0 0.888616046 3.11015641 3.99877246 0 7.11138295 0.888616046 8 3.99877246 4.8873885 7.11138295 8 8 7.11138295 4.8873885 3.99877246 8 0.888616046 7.11138295 0 3.99877246 3.11015641'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
        width: 2rem;
        height: 2rem;
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);
        right: 5rem;
        background-size: 1.4rem;
        background-repeat: no-repeat;
        background-position: center;
    }

    .search__autocomplete_dropdown {
        position: absolute;
        background-color: color(Gray, Gray-200);
        padding: .8rem;
        top: 4.8rem;
        z-index: 1;
        left: -4.5rem;
        right: -4.5rem;
        display: flex;
        flex-direction: column;
        gap: .8rem;
        max-height: 80vh;
        overflow: hidden;

        @include breakpoint(small only) {
            left: 2.4rem;
            right: 2.4rem;
        }

        section {
            background-color: color(Base, White);

            &:not(.--product) {
                padding: .8rem 1.2rem;
            }

            &.--product {
                overflow-x: auto;
                scroll-behavior: smooth;
            }

            > span {
                font-size: 1.2rem;
                font-weight: 600;
                display: block;
                margin: 0 0 .4rem;
            }

            ul {
                li {
                    display: flex;
                    align-items: center;
                    gap: .4rem;

                    a {
                        font-size: 1.6rem;
                        line-height: 2.6rem;
                        color: color(Gray, Gray-900);

                        .--highlight {
                            color: color(Base, Black);
                            font-weight: 600;
                        }

                        .--parent {
                            background-repeat: no-repeat;
                            background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.70963 4.99989L0.259628 1.54989L1.05 0.759521L5.29037 4.99989L1.05 9.24026L0.259628 8.44989L3.70963 4.99989Z' fill='#{color(Gray, Gray-700)}'/%3E%3C/svg%3E%0A");
                            background-position: left top .6rem;
                            background-size: .6rem;
                            padding-left: 1.2rem;
                            margin-left: .5rem;
                            color: color(Gray, Gray-700);
                            font-size: 1.4rem;
                            line-height: 2.6rem;

                            .--highlight {
                                color: color(Gray, Gray-800);
                            }
                        }

                        &:not(.--product) {
                            @include hover() {
                                text-decoration: underline;
                            }
                        }

                        &.--product {
                            display: flex;
                            gap: 1.6rem;
                            border-bottom: .1rem solid color(Gray, Gray-200);
                            padding: 1rem 1.2rem;
                            flex: 1;
                            @include transition();

                            @include hover() {
                                background-color: color(Gray, Gray-300);
                            }

                            .image__holder {
                                display: flex;
                                img {
                                    object-fit: contain;
                                }
                            }

                            .product__information {
                                flex: 1;
                                padding: .8rem 0;

                                > span {
                                    display: block;

                                    &.--title {
                                        color: color(Base, Black);
                                        font-size: 1.6rem;
                                        line-height: 2rem;
                                        font-weight: 600;
                                    }

                                    &.--brand {
                                        font-size: 1.3rem;
                                        color: color(Gray, Gray-600);
                                        line-height: normal;
                                        margin-top: -.4rem;
                                    }
                                }
                            }

                            .price__holder {
                                @include price-component();
                                color: color(Base, Black);
                                padding: .8rem 0;
                                flex-direction: column;
                                align-items: flex-end;
                                gap: 0;

                                .price__old-wrapper{
                                    display: flex;
                                    flex-direction: column;
                                    align-items: end;
                                }

                                .price__old-label {
                                    font-weight: 500;
                                    font-size: 1.2rem;
                                    line-height: normal;
                                    color: color(Gray, Gray-700);
                                }
                                .price__old-price {
                                    font-size: 1.4rem;
                                    line-height: normal;

                                    .--decimal {
                                        font-size: 1.1rem;
                                        line-height: 2.4rem;
                                    }
                                }

                                .price__special-price, .price__final-price {
                                    font-size: 2rem;
                                    line-height: 2rem;

                                    .--decimal {
                                        font-size: 1.4rem;
                                        line-height: 1.5rem;
                                    }
                                }
                            }
                        }
                    }

                    > span {
                        display: flex;
                        align-items: baseline;
                        font-size: 1.6rem;
                        line-height: 2.6rem;
                        color: color(Gray, Gray-800);

                        > span {
                            font-size: 1.4rem;
                            line-height: 2.6rem;
                            color: color(Base, PrimaryColor);
                            margin-left: .4rem;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
        }

        .no__results {
            font-size: 1.4rem;
            line-height: 3.6rem;
            padding: .8rem;

            span {
                background-color: color(Base, White);
                text-align: center;
                display: block;
            }
        }
    }
}
