.page-builder__landing-page, .home {
    &:not(.blog-post) {
        .breadcrumbs {
            display: none;
        }
    }
}

.page-builder__wrapper {
    margin: rem-calc(-$grid__padding) 0 0;

    @include breakpoint(medium) {
        margin: -2rem 0 0;
    }

    .page-builder__content-block {
        margin: $grid__padding 0;

        @include breakpoint(medium) {
            margin: 2rem 0;
        }

        ._header {
            margin-bottom: $grid__padding;
            display: flex;

            h2, h3 {
                @include title-after();
            }

            .blaze-controls {
                margin-left: auto;
            }
        }
        ul > br{
            //'Fix' for wysiwyg editor inserting br tag in ul
            display:none;
        }
    }
}

.page-builder__tab-container {
    dd {
        display: none;
    }

    &.--active {
        dd {
            display: block;
        }
    }

    &.--padding dd {
        padding: .5rem 2rem;

        > div {
            padding: .5rem 0;
        }
    }
}

.page-builder__navigation {
    margin: 2.4rem 0;

    @include breakpoint(medium) {
        margin-right: .8rem;
    }

    h3 {
        font-size: 1.8rem;
        line-height: 2.8rem;

        @include breakpoint(small only) {
            line-height: 4.4rem;
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_0_3' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='25'%3E%3Cpath d='M0 0.763H24V24.763H0V0.763Z' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_0_3)'%3E%3Cpath d='M12 15.802L6.346 10.148L7.4 9.094L12 13.694L16.6 9.094L17.653 10.148L12 15.802Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: right center;
            border-bottom: .1rem solid color(Gray, Gray-200);
            border-top: .1rem solid color(Gray, Gray-200);

            &.--active {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_0_3' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='25'%3E%3Cpath d='M0 24.237H24V0.237H0V24.237Z' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_0_3)'%3E%3Cpath d='M12 9.198L6.346 14.852L7.4 15.906L12 11.306L16.6 15.906L17.653 14.852L12 9.198Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
            }
        }
    }

    ul {
        display: none;

        @include breakpoint(medium) {
            display: block;
            margin: 1.2rem 0 0 .3rem;
            border-left: .2rem solid color(Gray, Gray-150);

            li {
                padding: 0 1rem;

                a {
                    position: relative;
                    display: inline-block;
                    font-size: 1.6rem;
                    line-height: 3rem;
                    padding: .2rem 0;

                    &:hover, &.active {
                        color: color(Base, Black);
                    }
                }

                &.pipe {
                    a {
                        &:before {
                            transition: background-color .15s ease-in-out;
                            content: '';
                            position: absolute;
                            width: .2rem;
                            top: 0;
                            bottom: 0;
                            left: 0;

                            @include breakpoint(medium) {
                                left: -1.2rem;
                            }
                        }

                        &:hover, &.active {
                            text-shadow: .1rem 0 color(Base, Black);

                            &::before {
                                background: color(Base, Black);
                            }
                        }
                    }
                }
            }
        }
        @include breakpoint(small only) {
            li {
                padding: 0 $grid__padding;
                border-bottom: .1rem solid color(Gray, Gray-200);

                a {
                    display: block;
                    font-size: 1.5rem;
                    line-height: 5rem;

                    &.active {
                        text-shadow: .1rem 0 color(Base, Black);
                    }
                }
            }
        }
    }
}
