.catalog__category-list {
    margin: 1.6rem 0 0;
    position: relative;

    @include breakpoint(small only) {
        margin: 0 rem-calc(-$grid__padding);
    }

    > ul {
        padding: .1rem 0 0 .1rem;
        margin: -.1rem 0 0;
    }

    > ul > li, div {
        .slider__product {
            > div {
                height: 100%;
                border-top: .1rem solid color(Gray, Gray-150);
                display: flex;
                flex-flow: column;

                > a {
                    flex: 1;
                }
            }
        }

        > a {
            transition: transform .2s ease-in;
            display: block;
            border: .1rem solid color(Gray, Gray-150);
            margin: -.1rem 0 0 -.1rem;
            z-index: 3;
            position: relative;
            background-color: color(Base, White);
            height: calc(100% + .1rem);

            .product__wishlist {
                position: absolute;
                top: 1.6rem;
                right: 1.6rem;
                z-index: 1;
                display: none;

                ._counter {
                    display: block;
                    font-size: 1.2rem;
                    line-height: 1.4;
                    text-align: center;
                }

                ._wishlist {
                    @include transition();
                    display: block;
                    width: 2.4rem;
                    height: 2.4rem;
                    margin: 0 auto;
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_978_607' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_978_607)'%3E%3Cpath d='M12 20.3268L10.8962 19.3345C9.23847 17.8306 7.86763 16.5383 6.78365 15.4576C5.69968 14.3768 4.84072 13.4149 4.20675 12.572C3.57277 11.729 3.12982 10.9601 2.8779 10.2653C2.62597 9.57039 2.5 8.86526 2.5 8.14988C2.5 6.73066 2.97852 5.54253 3.93558 4.58548C4.89263 3.62843 6.08076 3.1499 7.49998 3.1499C8.37306 3.1499 9.19806 3.35408 9.97498 3.76243C10.7519 4.17076 11.4269 4.75634 12 5.51915C12.5731 4.75634 13.2481 4.17076 14.025 3.76243C14.8019 3.35408 15.6269 3.1499 16.5 3.1499C17.9192 3.1499 19.1073 3.62843 20.0644 4.58548C21.0214 5.54253 21.5 6.73066 21.5 8.14988C21.5 8.86526 21.374 9.57039 21.1221 10.2653C20.8701 10.9601 20.4272 11.729 19.7932 12.572C19.1592 13.4149 18.3019 14.3768 17.2211 15.4576C16.1403 16.5383 14.7679 17.8306 13.1038 19.3345L12 20.3268ZM12 18.2999C13.6 16.8601 14.9166 15.6262 15.95 14.598C16.9833 13.5698 17.8 12.6765 18.4 11.9182C19 11.1598 19.4166 10.4864 19.65 9.89795C19.8833 9.30949 20 8.72679 20 8.14988C20 7.14988 19.6666 6.31654 19 5.64988C18.3333 4.98321 17.5 4.64988 16.5 4.64988C15.7102 4.64988 14.9804 4.87392 14.3106 5.322C13.6407 5.77007 13.1102 6.39347 12.7192 7.1922H11.2808C10.8833 6.38707 10.3513 5.76206 9.6846 5.31718C9.01793 4.87231 8.28973 4.64988 7.49998 4.64988C6.50639 4.64988 5.67466 4.98321 5.00478 5.64988C4.33491 6.31654 3.99998 7.14988 3.99998 8.14988C3.99998 8.72679 4.11664 9.30949 4.34998 9.89795C4.58331 10.4864 4.99998 11.1598 5.59998 11.9182C6.19998 12.6765 7.01664 13.5682 8.04998 14.5932C9.08331 15.6182 10.4 16.8537 12 18.2999Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: contain;

                    &._active {
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 20.1769L11.3962 19.1846C9.73847 17.6807 8.36763 16.3884 7.28365 15.3077C6.19968 14.2269 5.34072 13.265 4.70675 12.4221C4.07277 11.5791 3.62982 10.8102 3.3779 10.1154C3.12597 9.42048 3 8.71536 3 7.99998C3 6.58076 3.47852 5.39263 4.43558 4.43558C5.39263 3.47853 6.58076 3 7.99998 3C8.87306 3 9.69806 3.20418 10.475 3.61253C11.2519 4.02086 11.9269 4.60643 12.5 5.36925C13.0731 4.60643 13.7481 4.02086 14.525 3.61253C15.3019 3.20418 16.1269 3 17 3C18.4192 3 19.6073 3.47853 20.5644 4.43558C21.5214 5.39263 22 6.58076 22 7.99998C22 8.71536 21.874 9.42048 21.6221 10.1154C21.3701 10.8102 20.9272 11.5791 20.2932 12.4221C19.6592 13.265 18.8019 14.2269 17.7211 15.3077C16.6403 16.3884 15.2679 17.6807 13.6038 19.1846L12.5 20.1769Z' fill='%23FC0F40'/%3E%3C/svg%3E%0A");
                    }
                }
            }

            .product__tile {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 1.2rem;
                padding: $grid__padding;
                height: 100%;

                .sticker__wrapper .product__stickers .sticker__group {
                    &.top_left {
                        top: rem-calc(-$grid__padding);
                        left: rem-calc(-$grid__padding);
                    }

                    &.top_right {
                        top: 3rem;
                        right: rem-calc(-$grid__padding);
                    }

                    &.bottom_left {
                        bottom: 0;
                        left: rem-calc(-$grid__padding);
                    }

                    &.bottom_right {
                        bottom: 0;
                        right: rem-calc(-$grid__padding);
                    }
                }

                .image__holder {
                    @include background-image-logo();
                    display: flex;
                    background-size: 40%;

                    img {
                        width: 100%;
                        height: 100%;
                        aspect-ratio: 1;
                        object-fit: scale-down;
                    }
                }

                .product__holder {
                    display: flex;
                    flex-direction: column;
                    gap: .6rem;
                    height: 100%;

                    @include breakpoint(medium) {
                        gap: $grid__padding;
                    }

                    ._manufacturer {
                        background-repeat: no-repeat;
                        background-size: contain;
                        height: 1.8rem;

                        @include breakpoint(small only) {
                            max-width: 60%;
                            background-position: left center;
                        }
                    }

                    h2 {
                        font-size: 1.4rem;
                        line-height: 1.8rem;
                        flex: 1;
                    }

                    .tile__footer {
                        display: flex;
                        align-items: center;
                        gap: .8rem;
                        justify-content: space-between;

                        @include breakpoint(small only) {
                            flex-flow: column;
                            align-items: flex-start;
                        }

                        .price__holder {
                            @include price-component();
                            gap:0;
                            flex-direction: column;
                            align-items: start;
                        }
                        .price__old-wrapper{
                            display:flex;
                            line-height: 1.4rem;
                            align-items: center;
                            gap: .4rem;
                        }
                        .price__old {
                            font-size: 1.2rem;
                            .--decimal {
                                font-size: 1.1rem;
                            }
                        }
                        .price__old-label{
                            font-weight: 500;
                            font-size:1.3rem;
                        }
                    }
                }
            }

            &._overlay {
                .product__colors {
                    padding: .6rem;
                    position: absolute;
                    visibility: hidden;
                    opacity: 0;
                    width: 6rem;
                    top: -.1rem;
                    bottom: 0;
                    background-color: color(Base, White);
                    border: .1rem solid color(Gray, Gray-150);
                    border-right-color: color(Base, White);
                    right: 100%;

                    > ul {
                        display: flex;
                        flex-direction: column;
                        gap: .6rem;

                        a {
                            @include transition();
                            @include border-radius();
                            display: flex;
                            overflow: hidden;
                            border: .1rem solid color(Gray, Gray-150);
                            aspect-ratio: 1;
                            align-items: center;
                            justify-content: center;
                            font-size: 1.8rem;
                            font-weight: 700;

                            @include hover() {
                                border-color: color(Gray, Gray-500);
                            }
                        }
                    }
                }

                .product__sizes {
                    position: absolute;
                    border: .1rem solid color(Gray, Gray-150);
                    border-top: none;
                    background: color(Base, White);
                    margin: -.1rem 0 0;
                    visibility: hidden;
                    opacity: 0;
                    left: -6rem;
                    right: -.1rem;
                    top: 100%;
                    padding: .6rem;

                    &._no-colors {
                        left: -.1rem;
                        padding: .6rem;
                    }

                    > span {
                        font-size: 1.4rem;
                        line-height: 2.2rem;
                        font-weight: 700;

                        &.in-stock {
                            padding: .2rem .2rem .2rem 2rem;
                            background-repeat: no-repeat;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(Gray, Gray-700)}' class='size-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z' /%3E%3C/svg%3E%0A");
                            background-position: left center;
                            font-weight: normal;
                            color: color(Gray, Gray-700);
                            display: flex;
                            background-size: 1.6rem;
                            margin: 0 .4rem;
                        }
                    }

                    ul {
                        display: grid;
                        gap: .6rem;
                        grid-template-columns: repeat(5, 1fr);

                        li {
                            > a, > span {
                                @include clickable-label-item();
                                padding: .2rem;

                                &._more, &._more:hover {
                                    border: none;
                                    font-weight: 700;
                                    font-size: 1.6rem;
                                }
                            }

                            &._one-size {
                                > a, > span {
                                    font-size: 1.2rem;
                                }
                            }
                        }
                    }
                }

                @include breakpoint(medium) {
                    &._active {
                        transform: translate(1.5rem, -1.5rem);
                        z-index: 4;

                        .product__wishlist {
                            display: block;
                        }

                        .product__colors, .product__sizes {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .product__list-banner {
            background-color: color(Base, Black);

            > picture {
                display: flex;
                height: 100%;
            }

            &:not(.--full-width) {
                > picture {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: scale-down;
                    }
                }
            }
        }

        .add-to-cart__holder {
            background-color: color(Base, White);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: .4rem;
            padding: .8rem;
            border: .1rem solid color(Gray, Gray-150);
            border-top: none;
            margin-left: -.1rem;

            select {
                height: 3.6rem;
                line-height: 3.6rem;
                font-size: 1.2rem;
                padding: 0 3.2rem 0 1.2rem;
                background-size: 1.2rem;
                background-position: right 1.2rem center;
            }

            .--options {
                flex: 1;

                select {
                    width: 100%;
                }
            }

            > select {
                width: auto;
            }

            select {
                white-space: nowrap;

                @media (max-width: 376px) {
                    font-size: 1.1rem;
                    padding: 0 2.5rem 0 1rem;
                    background-size: 1rem;
                    background-position: right 1rem center;
                }
            }

            .global__message {
                flex-basis: 100%;
                font-size: 1.2rem;
            }

            button:disabled.--add-to-cart.--loading {
                padding-left: 0;
            }

            @include breakpoint(small only) {
                > button {
                    text-align: center;
                    flex: 0 0 100%;
                }
            }
        }
    }

    .blaze-container {
        .blaze-track-container {
            @include border-radius();
            border-left: .1rem solid color(Gray, Gray-150);
            padding: .1rem 0;
        }

        .slider__slide {
            > li, > div {
                height: 100%;
                border-top: .1rem solid color(Gray, Gray-150);
                display: flex;
                flex-flow: column;

                > a {
                    flex: 1;
                }
            }
        }
    }
}
