.customer-account {
    &:not(:is(body)){
        padding-top: 30px;
        @include breakpoint(small only) {
            padding-top: 15px;
        }
    }
    @include breakpoint(small only) {
        .customer__account-navigation {
            display: none;
        }
    }

    .breadcrumbs {
        display: none;
    }

    h1, h2 {
        font-size: 22px;
        line-height: 26px;
        margin: 0 0 10px;
    }

    h3 {
        font-size: 16px;
        line-height: 18px;
    }

    small {
        font-size: 13px;
        position: relative;
        top: -10px;
    }

    .order__status {
        margin-left: auto;
        line-height: 26px;
        font-size: 1.6rem;
    }

    table {
        width: 100%;
        font-size: 14px;
        @include breakpoint(small only) {
            margin: 0 0 15px;
        }

        th {
            text-align: left;
            border-bottom: 1px solid color(Gray, Gray-300);
            padding: 5px 0;
        }

        td {
            padding: 5px 0;
            vertical-align: middle;

            a {
                font-size: 1.4rem;
            }

            a:hover {
                text-decoration: underline;
            }

            &.--delivery {
                background-image: none;

                strong {
                    display: block;
                    font-size: 1.5rem;
                    line-height: normal;
                }

                span {
                    color: color(Gray, Gray-800);
                    display: block;
                    font-size: 1.3rem;
                    line-height: normal;
                }
            }
        }

        .a-right {
            text-align: right;
        }

        .a-center {
            text-align: center;
        }
    }

    p._intro {
        margin: 0 0 20px;
    }

    .customer__block {
        margin: 0 0 20px;
        padding: 0 0 20px;
        border-bottom: 1px solid color(Gray, Gray-300);
        @include breakpoint(small only) {
            .small-24 {
                margin-bottom: 20px;
            }
        }

        ._header {
            display: flex;
            margin: 0 0 10px;

            h3 {
                line-height: 20px;
                margin: 0;
            }

            a {
                margin-left: auto;
                line-height: 20px;
                font-size: 14px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &._last {
            border-bottom: none;
        }

        h3 {
            margin: 0 0 15px;
        }

        .messages {
            margin: 0;
        }
    }

    .customer__account-navigation {
        @include side-bar();
    }

    ._order-comment {
        margin: 0 0 20px;

        h3 {
            margin: 0 0 10px;
        }

        li {
            display: flex;

            span {
                display: block;
                line-height: 24px;
                font-size: 14px;

                a {
                    text-decoration: underline;
                    font-size: 14px;
                }

                &:last-of-type {
                    margin-left: auto;
                    font-size: 12px;
                    color: color(Gray, Gray-400);
                }
            }
        }
    }

    .address__block {
        @include breakpoint(medium up) {
            max-width: 85%;
        }
        background: color(Gray, Gray-200);
        padding: 15px;
        margin: 10px 0 20px;

        .delivery__holder {
            margin: 1.5rem 0 0;

            > strong {
                font-size: 1.4rem;
                display: block;
                margin: 0 0 .5rem;
                font-weight: 600;
            }

            .--delivery {
                background-position: left;

                strong {
                    display: block;
                    font-size: 1.5rem;
                    line-height: normal;
                }

                span {
                    color: color(Gray, Gray-800);
                    display: block;
                    font-size: 1.3rem;
                    line-height: normal;
                }
            }
        }

        > span {
            font-size: 15px;
            line-height: 21px;
            display: inline-block;
        }

        address {
            font-size: 15px;
            line-height: 21px;
        }

        a, button {
            font-size: 14px;
            display: inline-block;
            margin: 5px 0 0;
            font-weight: 600;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .order__totals {
        margin: 30px 0 0;
        text-align: right;
        @include breakpoint(small only) {
            margin: 0 0 15px;
        }

        ul {
            margin-left: auto;

            li {
                padding: 3px 0;
                font-size: 1.6rem;

                strong {
                    font-size: 18px;
                }
            }
        }
    }

    .customer__wishlist {
        @include breakpoint(small only) {
            margin: 0 0 15px;
        }
    }

    .order__actions {
        margin: 0 0 $grid__gutter;
        border-bottom: .1rem solid color(Gray, Gray-300);
        padding: 0 0 $grid__gutter;

        h3 {
            margin: 0 0 $grid__padding;
        }

        div {
            display: flex;
            flex-wrap: wrap;
            gap: $grid__padding;
        }
    }
}

.customer-account_box {
    @include breakpoint(small only) {
        margin: 0 0 15px;
    }

    h3 {
        margin: 0 0 15px;
    }

    &._background {
        @include border-radius();
        background: color(Gray, Gray-200);
        padding: 15px;
    }

    .checkout__field {
        &.postcode, &.street_1 {
            flex: 2 1 0;
        }

        &.city {
            flex: 3 1 0;
        }
    }

    .field__actions {
        ._forget {
            margin-left: 10px;
            font-size: 14px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    ul {
        li {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(Alert, Success)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M5 12l5 5l10 -10' /%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center left;
            padding: 1rem 0 1rem 3rem;

            strong {
                display: block;
                font-size: 1.6rem;
            }

            span {
                font-size: 14px;
            }
        }
    }

    &._register {
        a {
            margin-top: 15px;
        }
    }
}

.__reset {
    .messages._top {
        margin-left: 25%;
        @include breakpoint(small only) {
            margin-left: 0;
        }
    }
}

.__login-msg {
    .messages._top {
        margin-left: 4.1666666667%;
        @include breakpoint(small only) {
            margin-left: 0;
        }
    }
}
